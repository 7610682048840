const regionList = [{
    title: 'NA',
    disabled: false,
    rank: true,
    daily: true,
    serverName: 'NA1',
    tagName: 'NA1',
    continent: true,
}, {
    title: "EUW",
    disabled: false,
    rank: true,
    daily: true,
    serverName: 'EUW1',
    tagName: 'EUW',
    continent: true,

}, {
    title: "EUN",
    disabled: false,
    rank: true,
    daily: true,

    serverName: 'EUN1',
    tagName: 'EUNE',

    continent: true,
}, {
    title: "KR",
    disabled: false,
    rank: true,
    daily: true,
    serverName: 'KR',
    tagName: 'KR1',

    continent: false,
}, {
    title: "JP",
    disabled: false,
    rank: true,
    daily: true,

    serverName: 'JP1',
    tagName: 'JP1',

    continent: false,
}, {
    title: "OCE",
    disabled: true,
    rank: false,
    daily: true,

    serverName: 'OC1',
    tagName: 'OCE',

    continent: true,
}, {
    title: "LAN",
    disabled: true,
    rank: false,
    daily: true,

    serverName: 'LA1',
    tagName: 'LAN',
    continent: true,
}, {
    title: "LAS",
    disabled: true,
    rank: false,
    daily: true,

    serverName: 'LA2',
    tagName: 'LAS',

    continent: true,
}, {
    title: "BR",
    disabled: false,
    rank: true,
    daily: true,

    serverName: 'BR1',
    tagName: 'BR1',

    continent: false,
}, {
    title: "TR",
    rank: true,
    daily: true,

    disabled: false,
    serverName: 'TR1',
    tagName: 'TR1',

    continent: false,
}, {
    title: "RU",
    rank: true,
    daily: false,

    disabled: true,
    serverName: 'RU1',
    tagName: 'RU1',

    continent: false,
}, {
    title: "PH",
    rank: true,
    daily: false,

    disabled: true,
    serverName: 'PH2',
    tagName: 'PH2',

    continent: false,
}, {
    title: "SG",
    rank: true,
    daily: false,

    disabled: true,
    serverName: 'SG2',
    tagName: 'SG2',

    continent: false,
}, {
    title: "TH",
    rank: true,
    daily: false,

    disabled: true,
    serverName: 'TH2',
    tagName: 'TH2',

    continent: false,
}, {
    title: "TW",
    rank: true,
    daily: true,

    disabled: false,
    serverName: 'TW2',
    tagName: 'TW2',

    continent: false,
}, {
    title: "VN",
    rank: true,
    daily: true,

    disabled: false,
    serverName: 'VN2',
    tagName: 'VN2',

    continent: false,
}, {
    title: "ME",
    rank: false,
    daily: true,

    disabled: true,
    serverName: 'ME1',
    tagName: 'ME1',

    continent: true,
}
];


export default regionList
