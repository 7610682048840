import React from 'react';
import KoreanAd from "./KoreanAd";
import SummonerCustomAd from "../../ad/SummonerCustomAd";

const SummonerAdBanner = ({status}) => {



    if(status.name === 'Faker'){
        return (
            <SummonerCustomAd indexArray={['F','F','F','F','D1','F','F','F','F','D2']}/>
        )
    }


    // if(status.name === 'Gumayusi'){
    //     return (
    //         <SummonerCustomAd indexArray={['G','G','G','G','D1','G','G','G','G','D2']}/>
    //     )
    // }

    return (
        <div>
            <KoreanAd/>
        </div>
    )
};


export default SummonerAdBanner;