import React, {memo, useEffect, useRef} from 'react';
import styled from "styled-components";
import VenatusAd from "./VenatusAd";


const SmallRail = styled.div`
  display: flex;
  justify-content: center;
  width: 300px;
  margin-bottom: 15px;
  max-height: 250px;
`;


const LeftSideWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
`;


const RightSIdeStickyBox = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const StickyBoxWrapper = styled.div`
  position: fixed;
  pointer-events: none;
  overflow: hidden;
  text-align: center;
`;
const RightRailAd = () => {
    const ref = useRef();

    // return <VenatusAd placementName={'vertical_sticky'}/>


    useEffect(() => {
        if (ref?.current?.style) {
            ref.current.style.inset = `100px 0px 0px ${(window.innerWidth + 1110 + 12) / 2}px`;
            window.addEventListener('resize', () => {
                if (ref?.current?.style?.inset) {
                    ref.current.style.inset = `100px 0px 0px ${(window.innerWidth + 1110 + 12) / 2}px`;
                }
            })
        }
    }, [])

    return (
        <StickyBoxWrapper ref={ref}>
            {/*<SmallRail data-ad={'right-rail-1'}>*/}
            {/*    <VenatusAd placementName={'vertical_sticky'}/>*/}
            {/*</SmallRail>*/}
            <LeftSideWrapper data-ad={'right-rail-2'}>
                <VenatusAd placementName={'vertical_sticky-right'}/>
            </LeftSideWrapper>
        </StickyBoxWrapper>
    )
}

export default memo(RightRailAd);