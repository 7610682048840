import React, {useEffect, useMemo, useState} from "react";
import styled from "styled-components";
import _ from "lodash";

const FadeInBox = styled.div`
  animation: fadein 1s linear;

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const Adcalc = (value) => {
    if (value < 11) {
        return 1
    } else if (value < 46) {
        return 2
    } else if (value < 81) {
        return 3
    } else if (value < 91) {
        return 8
    } else {
        return 9
    }
};

const randomNumber100 = () => {
    return _.random(1, 100);
}
const KoreanAd = () => {

    const [adState, setAdState] = useState(() => {
        return Adcalc(randomNumber100())
    });


    const changeState = () => {
        setAdState(prev => {
            let result = prev;
            while (true) {
                if (result !== prev) {
                    break;
                } else {
                    result = Adcalc(randomNumber100());
                }
            }
            return result
        })
    }


    if ([1].includes(adState)) {
        return (
            <TimeOutBox key={adState + "adState1"} time={5000} changeState={changeState}>
                <FadeInBox>
                    <a href={'https://www.instagram.com/faker/'} target={'_blank'}>
                        <img src={'./images/ad/ad_state1.png'} width={'100%'} height={120} alt={''}
                        />
                    </a>
                </FadeInBox>
            </TimeOutBox>
        )
    }

    if ([2].includes(adState)) {
        return (
            <TimeOutBox key={adState + "adState2"} time={5000} changeState={changeState}>
                <FadeInBox>
                    <a href={'https://www.youtube.com/watch?v=P_t546tENEY'} target={'_blank'}>
                        <img src={'./images/ad/ad_state2.png'} width={'100%'} height={120} alt={''}
                        />
                    </a>
                </FadeInBox>
            </TimeOutBox>
        )
    }

    if ([3].includes(adState)) {
        return (
            <TimeOutBox key={'adState3'} time={5000} changeState={changeState}>
                <TimeBox/>
            </TimeOutBox>
        )
    }

    if ([8].includes(adState)) {
        return (
            <TimeOutBox time={3000} changeState={changeState} key={adState}>
                <FadeInBox>
                    <a href={'https://deeplol-notice.notion.site/1caf4d23843f80f6825bc091b413b7c6?pvs=4'} target={'_blank'}>
                        <img src={'./images/ad/ad_deeplol_spot.png'} width={'100%'} height={120} alt={''}
                        />
                    </a>
                </FadeInBox>
            </TimeOutBox>
        )
    }

    if ([9].includes(adState)) {
        return (
            <TimeOutBox time={3000} changeState={changeState} key={adState}>
                <FadeInBox key={adState} time={3000} changeState={changeState}>
                    <a href={'https://deeplol-notice.notion.site/1caf4d23843f80f6825bc091b413b7c6?pvs=4'} target={'_blank'}>
                        <img src={'./images/ad/ad_deeplol_spot2.png'} width={'100%'} height={120} alt={''}
                        />
                    </a>
                </FadeInBox>
            </TimeOutBox>
        )
    }


    return (
        <TimeOutBox time={5000} changeState={changeState}>
            <FadeInBox>
                <img src={'./images/ad/ad_state1.png'} width={'100%'} height={120} alt={''}
                />
            </FadeInBox>
        </TimeOutBox>
    )
}


const TimeBox = () => {
    const time = useMemo(() => {
        const date = new Intl.DateTimeFormat('en', {
            timeZone: 'Asia/Seoul',
            hour12: false,
            hour: '2-digit'
        }).format(new Date());
        return Number(date)
    }, []);

    const href = useMemo(() => {
        if (time > 2 && time < 18) {
            return "https://www.youtube.com/shorts/7XUrRjSMNCg"
        } else {
            return "https://chzzk.naver.com/eb509a24d92170db1ba9bafb58696be0"
        }
    }, [time]);


    return (
        <FadeInBox>
            <a href={href} target={'_blank'}>
                <img src={'./images/ad/ad_state3.png'} width={'100%'} height={120} alt={''}
                />
            </a>
        </FadeInBox>
    )
}

const TimeOutBox = ({time, changeState, children}) => {
    useEffect(() => {
        const timeout = setTimeout(() => {
            changeState();
        }, time)
        return () => {
            clearTimeout(timeout)
        }
    }, [])
    return children
}


export default KoreanAd