import React, {useCallback} from 'react';
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {shortChampionIdsAndText} from "../../function";


const _URL_CHAMP = /\s|&|willump|!|/gi;
const useChampionFunc = () => {
    const {i18n} = useTranslation();
    const championData = useSelector((state) => state?.imageStore.champions);
    const history = useHistory();

    const getChampion = useCallback((id) => {
        if (Array.isArray(championData)) {
            return championData.find((data) => Number(data.champion_id) === Number(id));
        }
        return {};
    }, [championData, i18n.language]);

    const getChampionByName = useCallback((championName) => {
        if (typeof championName == 'string') {
            // const removeSearchName = championName.toLowerCase().replace(/\s+/g, '')
            // return championData.find((data) => {
            //     const getShortName = shortChampionIdsAndText(data.champion_id);
            //     console.log(getShortName)
            //     if (getShortName.includes(removeSearchName)) {
            //         return true;
            //     }
            //     const removeEmptyEn = data.champion_name_en.toLowerCase().replace(/\s+/g, '')
            //     const removeEmpty = data?.champion_name?.toLowerCase()?.replace(/\s+/g, '') || ""
            //     const removeEmptyKr = data.champion_name_kr.toLowerCase().replace(/\s+/g, '')
            //     return [removeEmptyEn, removeEmpty, removeEmptyKr].includes(removeSearchName)
            // });
            try {
                const regex = new RegExp(`^${championName.toLowerCase().replace(/\s+/g, '')}+`);
                return championData.find((data) => {
                    const getShortName = shortChampionIdsAndText(data.champion_id);

                    if (getShortName.includes(championName)) {
                        return true;
                    }
                    const enName = data.champion_name_en.toLowerCase();
                    const filterEnName = enName.replace(/'|"/, "");
                    return regex.test(filterEnName) || regex.test(data.cho_name) || regex.test(data.champion_name.replace(/\s+/g, ''))
                });
            } catch (e) {
                console.log("invalid regex");
            }

        }
        return null;
    }, []);


    const getUrlNameById = useCallback((id) => {
        const currentChamp = getChampion(id);
        if (currentChamp) {
            return getUrlNameByChampion(currentChamp);
        }
        return "";
    }, []);

    const getUrlNameByChampion = useCallback((champion) => {
        return calcUrlNameByName(champion?.champion_name_en);
    }, []);

    const calcUrlNameByName = useCallback((name) => {
        return name?.replace(_URL_CHAMP, "")?.toLowerCase()
    }, []);


    const getChampionName = useCallback((champion) => {
        if (typeof champion === 'number') {
            return getChampion(champion)?.champion_name;
        }
        return champion?.champion_name;
    }, [getChampion]);

    const navigateToBuild = useCallback((champion, extra = "") => {
        const champName = getUrlNameByChampion(champion);
        history.push(`/champions/${champName}/build${extra}`)
    }, []);

    const newTabNavigateToBuild = useCallback((champion, extra = "") => {
        const champName = getUrlNameByChampion(champion);
        window.open(`/champions/${champName}/build${extra}`)
    }, []);


    const newTabNavigateToBuildUrl = useCallback((champion, extra = "") => {
        const champName = getUrlNameByChampion(champion);
        return `/champions/${champName}/build${extra}`
    }, []);

    return {
        championData,
        navigateToBuild,
        getChampionByName,
        getChampion,
        getUrlNameById,
        getUrlNameByChampion,
        getChampionName,
        calcUrlNameByName,
        newTabNavigateToBuild,
        newTabNavigateToBuildUrl,
    }
};

export default useChampionFunc;
