import React from 'react';
import styled from "styled-components";
import KoreanAd from "../components/ad/KoreanAd";

const Wrapper = styled.div`
  width: 100%;
  margin: 9px 0;

  .add_to_GenG {
    border-radius: 6px;
  }
`;
const SponsorAD = () => {


    // <a href={'https://bit.ly/3XYhema'} target={'_blank'}>
    //     <img src={'./images/ad_geng_kr.jpg'} width={'100%'} height={50} alt={''}
    //          className={'add_to_Gen'}/>
    // </a>

    return (
        <Wrapper>
                <KoreanAd/>
        </Wrapper>
    );
};

export default SponsorAD;