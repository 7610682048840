import React, {useMemo} from 'react';
import {calcTeamLuck, normCDF} from "../../function";
import LuckLayout from "../summoner/NewOverview/Luck/LuckLayout";
import TeamLuckComponent from "./TeamLuckComponent";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import {useSelector} from "react-redux";

const TeamLuckOverview = ({match}) => {
    const {t} = useTranslation();
    const summoner = useSelector((state) => state.summoner.summoner);

    const avgAiScore = useMemo(() => {
        try {
            const normalizeList = match.reduce((sum, cur) => {
                const myMatch = cur.participants_list.find((data) => data.puu_id === summoner.summoner_basic_info_dict.puu_id);
                const sameTeamArray = cur.participants_list.filter((data) => data.side === myMatch.side && data.puu_id !== myMatch.puu_id);

                const avgValue = sameTeamArray?.reduce((sum2, data, index) => {
                    sum2.aiScoreAvg = Math.floor(data.ai_score) + Math.floor(sum2.aiScoreAvg);
                    return sum2;
                }, {aiScoreAvg: 0});


                const avgAiScore = avgValue.aiScoreAvg / sameTeamArray.length;

                const calcAvgValue = calcTeamLuck({avgAiScore: avgAiScore, myAiScore: myMatch.ai_score})
                sum.push(calcAvgValue)
                return sum;
            }, [])

            const reducedList = normalizeList?.reduce((sum, data, index) => {
                sum.aiScoreAvg = data + sum.aiScoreAvg;
                return sum;
            }, {aiScoreAvg: 0});

            return reducedList.aiScoreAvg / normalizeList?.length
        } catch (e) {
            console.log("??????", e)
            return 0
        }
    }, [match]);


    return (
        <LuckLayout title={t('teamLuck.title')}>
            <TeamLuckComponent
                godLikeScore={54}
                solidScore={51}
                balancedScore={49}
                messyScore={46}
                teamLuckValue={avgAiScore}
                TeamLuckCustom={TeamLuckCustom}
            />
        </LuckLayout>
    );
};
const TeamLuckWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TeamLuckBold = styled.span`
  color: ${props => props.theme.color.dark_200};
  margin-left: 4px;
  font-weight: 800;
  font-size: 12px;
`;
const TeamTopBottomText = styled.span`
  font-size: 12px;
  font-weight: 500;
  color: ${props => props.theme.color.dark_200};
`;
const TeamLuckCustom = ({src, title, teamLuckValue}) => {
    const {t} = useTranslation()


    const topValue = useMemo(() => {
        try {
            const z = (teamLuckValue - 50) / 5
            const cdf = normCDF(z)
            return cdf
        } catch (e) {
            return 0;
        }
    }, [teamLuckValue])

    const getTopBottomValue = useMemo(() => {
        return ((1 - topValue) * 100).toFixed(0)
    }, [teamLuckValue])

    return (
        <TeamLuckWrapper>
            <img
                src={`./images/icon_teamluck_${src}`}
                width={16}
                height={16}
                alt={''}
            />
            <TeamLuckBold>
                {title}<TeamTopBottomText> ({t('teamLuck.top')} {getTopBottomValue}%)</TeamTopBottomText>
            </TeamLuckBold>
        </TeamLuckWrapper>
    )
}

export default TeamLuckOverview;