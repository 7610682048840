import React from 'react';
import styled from "styled-components";
import {storePuuIdLocal} from "../../function";

const Wrapper = styled.div`
  cursor: pointer;
  background-color: #fff;
  border-radius: 100px;
  img {
    object-fit: cover;
    border-radius: 100px;
  }
`;
const EventPlayer = ({name, width, height, riot_id, riot_tag, puuId, isRoom,status,url_name,toSummoner,region,disable}) => {
    return (
        <Wrapper onClick={() => {
            if(disable) {
                return
            }

            if(toSummoner) {
                storePuuIdLocal(`${riot_id}-${riot_tag}`,region,puuId)
            } else {
                window.open(`/${status === 'Streamer' ? 'strm' : 'pro'}/${url_name}`, "_blank", "noreferrer")
            }
        }}>
            <img src={`${process.env.REACT_APP_ROOT_IMG}${status?.toLowerCase()}/${name}.png`} width={width} height={height} alt={''} className={'playerImage'} onError={(e) => e.target.src = '/images/img_unknown.svg'}/>
        </Wrapper>
    );
};

export default EventPlayer;