import React from 'react';
import styled from "styled-components";

const Wrapper = styled.div`
  flex: 1;
  padding: 7px 0;
  border: 1px solid ${props => props.theme.color.default_border_color};
  border-radius: 6px;
  background-color: ${props => props.theme.color.site_main_color};
`;
const ItemBox = styled.div`
  width: fit-content;
  margin: 0 auto;

  & + & {
    margin-top: 4px;
  }
`;
const TitleSpan = styled.span`
    font-size: 12px;
  font-weight: 500;
  color: ${props => props.theme.color.dark_100}
`;
const LuckLayout = ({title, children}) => {
    return (
        <Wrapper>
            <ItemBox>
                <TitleSpan>{title}</TitleSpan>
            </ItemBox>
            <ItemBox>
                {children}
            </ItemBox>
        </Wrapper>
    );
};

export default LuckLayout;