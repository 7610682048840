import React, {useCallback} from 'react';
import styled, {useTheme} from "styled-components";
import {useTranslation} from "react-i18next";
import useExtraEventAPI from "../../../common/hooks/useExtraEventAPI";
import {FlexBox} from "../../ui/styled";
import EventSoopTable from "../EventSoopTable";
import {ImageLane} from "../../common";

const Wrapper = styled.div`
  width: 100%;
`;

const ListWrapper = styled.div`
`;
const CardWrapper = styled.div`
  background-color: ${props => props.theme.color.site_main_color};
  border: 2px solid ${props => props.theme.color.green_100};
  border-radius: 6px;
  overflow: hidden;

  margin-top: 10px;
`;


const ExtraContent = () => {
    const {t} = useTranslation();
    const {eventQuery,} = useExtraEventAPI();

    const positionList = eventQuery.teamListByPosition

    return (
        <Wrapper>
            <GreenBox/>
            <ListWrapper>
                <CardWrapper>
                    <PositionBox position={'top'}/>
                    <EventSoopTable list={positionList.top} team={""}
                                    disableTeam={true} most={true}/>
                </CardWrapper>
            </ListWrapper>
            <ListWrapper>
                <CardWrapper>
                    <PositionBox position={'jungle'}/>
                    <EventSoopTable list={positionList.jungle} team={""}
                                    disableTeam={true} most={true}/>
                </CardWrapper>
            </ListWrapper>
            <ListWrapper>
                <CardWrapper>

                    <PositionBox position={'middle'}/>
                    <EventSoopTable list={positionList.middle} team={""}
                                    disableTeam={true} most={true}/>
                </CardWrapper>
                {/*<EventChart list={item}*/}
                {/*            tierOff={currentTierOff}/>*/}
            </ListWrapper>
            <ListWrapper>
                <CardWrapper>

                    <PositionBox position={'bot'}/>
                    {/*<EventTeamComment leader={leader} team={leader.team_name} teamList={item} tag={score_sum}/>*/}
                    <EventSoopTable list={positionList.bot} team={""}
                                    disableTeam={true} most={true}/>
                </CardWrapper>
                {/*<EventChart list={item}*/}
                {/*            tierOff={currentTierOff}/>*/}
            </ListWrapper>
            <ListWrapper>
                <CardWrapper>
                    <PositionBox position={'supporter'}/>

                    {/*<EventTeamComment leader={leader} team={leader.team_name} teamList={item} tag={score_sum}/>*/}
                    <EventSoopTable list={positionList.supporter} team={""}
                                    disableTeam={true} most={true}/>
                </CardWrapper>
                {/*<EventChart list={item}*/}
                {/*            tierOff={currentTierOff}/>*/}
            </ListWrapper>
        </Wrapper>
    );
};


const RegionName = styled.div`
  font-weight: 700;
  font-size: 24px;
  color: ${props => props.theme.color.green_100};
  margin-left: 8.5px;
`;

const RegionCompo = ({region,teamName}) => {
   return (
       <FlexBox margin={'0 0 20px 0'}>
           <img src={`/images/event/flag_${region}.svg`} width={46} height={31} alt={''}/>
           <RegionName>
               {teamName}
           </RegionName>
       </FlexBox>

   )
}

const GreenBoxWrapper = styled.div`
  white-space: pre-wrap;
  text-align: center;
  line-height: 39px;
  margin: 10px 0 0 0;
  border: 2px solid ${props => props.theme.color.green_100};
  background-color: ${props => props.theme.color.green_200};
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: ${props => props.theme.color.green_100};
  font-weight: bold;
  font-size: 20px;
`;

const GreenBox = () => {
    const {t} = useTranslation()
    return (
        <GreenBoxWrapper>
            {t('extra.green')}
        </GreenBoxWrapper>
    )
}

const PositionBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 12px;
  border-bottom: 2px solid ${props => props.theme.color.green_100};

  .imgLane {
    width: 22px;
    height: 22px;
  }
`;
const PositionSpan = styled.span`
  font-size: 24px;
  font-weight: 700;
  color: ${props => props.theme.color.green_100};
`;
const PositionTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 36px;
  margin-left: 10px;
`

const PositionBox = ({position}) => {
    const theme = useTheme();
    const {t} = useTranslation();

    const getLaneName = useCallback((lane) => {
        switch (lane.toLowerCase()) {
            case 'top':
                return t('gameAnalytics.top')
            case 'jungle':
                return t('gameAnalytics.jungle')
            case 'middle':
                return t('gameAnalytics.mid')
            case 'bot':
                return t('gameAnalytics.bot')
            case 'supporter':
                return t('gameAnalytics.support')
            default:
                break;
        }
    }, []);


    return (
        <PositionBoxWrapper>
            <ImageLane
                lane={position}
                color={theme.color.theme === 'dark' ? 'light' : 'dark'}
            />
            <PositionTextWrapper>
                <PositionSpan>{getLaneName(position)} 포지션 랭킹</PositionSpan>
            </PositionTextWrapper>
        </PositionBoxWrapper>
    )


}

export default ExtraContent;