import React from 'react';
import {useTranslation} from "react-i18next";
import styled from "styled-components";

const TeamLuckComponent = ({teamLuckValue,TeamLuckCustom = TeamLuckItem,godLikeScore =59, solidScore= 52, balancedScore=49, messyScore=41,}) => {
    const {t} = useTranslation();
    if (teamLuckValue > godLikeScore) {
        return <TeamLuckCustom src={'godlike.gif'} title={t('teamLuck.godLike')} teamLuckValue={teamLuckValue}/>
    }

    if (teamLuckValue > solidScore) {

        return <TeamLuckCustom src={'solid.svg'} title={t('teamLuck.solid')} teamLuckValue={teamLuckValue}/>
    }
    if (teamLuckValue > balancedScore) {
        return <TeamLuckCustom src={'balanced.svg'} title={t('teamLuck.balanced')} teamLuckValue={teamLuckValue}/>
    }
    if (teamLuckValue > messyScore) {
        return <TeamLuckCustom src={'messy.svg'} title={t('teamLuck.messy')} teamLuckValue={teamLuckValue}/>
    }
    return <TeamLuckCustom src={'doomed.gif'} title={t('teamLuck.doomed')} teamLuckValue={teamLuckValue}/>
};

const TeamLuckWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TeamLuckText = styled.span`
  color: ${props => props.theme.color.dark_200};
  font-weight: 400;
  font-size: 12px;
  margin-left: 4px;
`;

const TeamLuckBold = styled.span`
  font-weight: 800;
`;

const TeamLuckItem = ({src, title}) => {
    const {t} = useTranslation()

    return (
        <TeamLuckWrapper>
            <img
                src={`./images/icon_teamluck_${src}`}
                width={16}
                height={16}
                alt={''}
            />
            <TeamLuckText>{t('teamLuck.title')} <TeamLuckBold>
                {title}
            </TeamLuckBold>
            </TeamLuckText>
        </TeamLuckWrapper>
    )
}

export default TeamLuckComponent;