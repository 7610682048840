import {useSuspenseQuery} from "@tanstack/react-query";
import {secretApi} from "../../api/api";
import {getDivisionValue, getTierValue, romanNumberConverter, sortChampData} from "../../function";


const addTierList = (list) => {

    return list.map((data) => {
        const splitTier = data?.tier?.split(" ");
        const tier = splitTier[0];
        const division = romanNumberConverter(splitTier?.[1]);

        return {
            ...data,
            tierScore: getTierValue(tier) + getDivisionValue(division)
        }
    })

}

const UseExtraEventAPI = () => {
    const query = useSuspenseQuery({
        queryKey: ['extraEventContent'],
        queryFn: async () => {
            const url = new URL(`${process.env.REACT_APP_CACHE_API}summoner/lilpa700_event`);
            const event = await secretApi.get(url.toString());


            const eventResult = event.data.teams_info


            const top = addTierList(eventResult.Top).filter((data) => data.position.toLowerCase() === 'top');
            const jungle = addTierList(eventResult.Jungle).filter((data) => data.position.toLowerCase() === 'jungle');
            const middle = addTierList(eventResult.Mid).filter((data) => data.position.toLowerCase() === 'mid');
            const bot = addTierList(eventResult.Bot).filter((data) => data.position.toLowerCase() === 'bot');
            const supporter = addTierList(eventResult.Support).filter((data) => data.position.toLowerCase() === 'support');


            const sort = [
                {value: "score", sortType: false},
                {value: "tierScore", sortType: false},
                {value: "lp", sortType: false}, {
                    value: "games",
                    sortType: false
                }, {
                    value: "wins",
                    sortType: false
                }]

            const topSort = sortChampData(top, sort)
            const jungleSort = sortChampData(jungle, sort)
            const middleSort = sortChampData(middle, sort)
            const botSort = sortChampData(bot, sort)
            const supporterSort = sortChampData(supporter, sort)

            return {
                teamListByPosition: {
                    top: topSort,
                    jungle: jungleSort,
                    middle: middleSort,
                    bot: botSort,
                    supporter: supporterSort
                },
            }
        },
        retry: 0,
        staleTime: Infinity,
        refetchOnWindowFocus: false,
    })
    return {
        eventQuery: query.data,
    }
};

export default UseExtraEventAPI;