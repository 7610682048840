import styled, {useTheme} from "styled-components";
import {useTranslation} from "react-i18next";
import {getLaneByIndex, romanNumberConverter, storePuuIdLocal, threedigits} from "../../function";
import React, {useCallback} from "react";
import {FlexBox} from "../ui/styled";
import EventPlayer from "./EventIcon";
import SmallInfluInfo from "../common/SmallInfluInfo";
import TierTag from "../common/TierTag";
import RankingWinRate from "../Ranking/RankingContent/RankingTable/RankingTableContent/RankingWinRate";
import {ImageLane} from "../common";
import ChampionImageWithLane from "../common/ChampionImageWithLane";
import {AI_SCORE_GOOD_TEXT, AI_SCORE_PERFECT_TEXT, AI_SCORE_VERY_BAD_TEXT} from "../../styled/scoreColor";

const Wrapper = styled.div`
`;
const Table = styled.table`
`;

const Thead = styled.thead`
  background-color: ${props => props.theme.color.green_200};
  border-bottom: 1px solid ${props => props.theme.color.green_100};
`;


const HeaderTh = styled.th`
  height: 56px;
  width: ${props => props.width};
  vertical-align: middle;
  text-align: center;
  white-space: pre-wrap;
  color: ${props => props.theme.color.green_100};
  font-weight: 500;
  font-size: 16px;
`;
const EventSoopTable = ({
                            list,
                            team,
                            indexPosition,
                            onlyToSummoner,
                            positionRank,
                            disableTeam,
                            most
                        }) => {

    const {t} = useTranslation();
    const getPosition = (data, index) => {
        if (indexPosition) {
            return getLaneByIndex(index)
        }

        if (most) {
            return data.top_position
        }

        return data.position
    }
    return (
        <Wrapper>
            <Table>
                <Thead>
                    <tr>
                        <HeaderTh width={'48px'}>#</HeaderTh>
                        <HeaderTh width={'64px'}/>
                        <HeaderTh width={'240px'}>Players</HeaderTh>
                        <HeaderTh width={'104px'}>Tier</HeaderTh>
                        <HeaderTh width={'184px'}>Win Rate</HeaderTh>
                        <HeaderTh width={'40px'}>Role</HeaderTh>
                        <HeaderTh width={'128px'}>Most</HeaderTh>
                        <HeaderTh width={'272px'}>Recent Games</HeaderTh>
                    </tr>
                </Thead>
                <tbody>
                {list.map((data, index) => {
                    const splitTier = data.tier.split(" ");
                    return (
                        <TableTr
                            score={data.score}
                            disableTeam={disableTeam}
                            positionRank={positionRank}
                            region={data.platform_id}
                            onlyToSummoner={onlyToSummoner}
                            key={index}
                            rank={index + 1}
                            live={data.live}
                            status={data.status}
                            championship_name={data?.championship_name}
                            url_name={data.url_name}
                            name={data.name} riot_id={data.riot_id_name}
                            puuId={data.puuId}
                            riot_tag={data.riot_id_tag_line}
                            team={data.pro_team}
                            tier={splitTier[0]}
                            division={splitTier[1]}
                            lp={data.lp}
                            win={data.wins}
                            lose={data.losses}
                            position={data.position}
                            topPosition={data.top_position}
                            most={data.most_champion}
                            win_table={data.win_table}
                        />
                    )
                })}
                </tbody>
            </Table>
        </Wrapper>
    );
};

const Td = styled.td`
  vertical-align: middle;
  height: 56px;
`;

const RankSpan = styled.span`
  font-size: 12px;
  font-weight: 400;
  color: ${props => props.theme.color.dark_100};
`;

const RelativeBox = styled.div`
  width: fit-content;
  height: fit-content;
  position: relative;
  cursor: pointer;
`;

const AbsoluteBox = styled.div`
  padding: 2px;
  background-color: ${props => props.theme.color.site_main_color};
  right: -7px;
  bottom: -3.5px;
  position: absolute;
  border-radius: 6px;
`;

const TR = styled.tr`
  background-color: ${props => props.theme.color.site_main_color};

  &.light + &.light {
    border-top: 1px solid ${props => props.theme.color.gray_200};
  }

  &.dark + &.dark {
    border-top: 1px solid ${props => props.theme.color.gray_200};
  }
`;

const SummonerBox = styled.div`
  margin-left: 21px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const SummonerName = styled.span`
  cursor: pointer;
  font-size: 16px;
  max-width: 117px;
  line-height: normal;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: ${props => props.theme.color.dark_100};
  font-weight: 800;

  &:hover {
    text-decoration: underline;
  }
`;

const TeamBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const LeaderImageBox = styled.div`
  display: flex;
  flex-direction: row;
`;
const LeaderImage = styled.img`
  max-height: 12px;
  margin-right: 3px;
`;
const TeamName = styled.span`
  line-height: normal;
  font-size: 11px;
  font-weight: 500;
  max-width: 96px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${props => props.color};
`;

const TagWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-right: 4px;
`;

const LPText = styled.span`
  color: ${props => props.theme.color.dark_100};
  font-size: 12px;
  flex: 1;
`;
const LP = styled.span`
  margin-left: 1px;
  color: ${props => props.theme.color.dark_200};
  font-size: 12px;
`;
const WinRateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;


  .win-rate-text {
    font-size: 12px;
    padding: 2px 4px;
  }

  .win-rate-bar {
    width: 112px;
  }

  .percent {
    font-size: 9px;
    color: ${props => props.theme.color.dark_200};
    margin-left: 0;
  }
`;
const LaneBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .imgLane {
    width: 20px;
    height: 20px;
  }
`;

const ChampionBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .champion-div + .champion-div {
    margin-left: 4px;
  }


  .imgDiv {
    border-radius: 4px;
  }


  .imgChamp {
    width: 32px;
    height: 32px;
  }
`;


const Box = styled.div`
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  font-size: 12px;
  font-weight: 700;
`;
const WinLoseBox = styled(Box)`

  background-color: ${props => {
    let color = props.theme.color.blue_100
    if (props.win === 0) color = props.theme.color.red_100
    if (props.win === 9) color = props.theme.color.site_background_color_dark_300
    return color
  }};
  background-image: url(${props => {
    let image = 'none';
    if (props.win === 9) image = `"data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='3' ry='3' stroke='${encodeURIComponent(props.theme.color.default_border_color)}' stroke-width='2' stroke-dasharray='3%2c5' stroke-dashoffset='8' stroke-linecap='square'/%3e%3c/svg%3e"`;
    return image
  }});

  color: ${props => {
    let color = '#fff';
    if (props.win === 9) color = props.theme.color.dark_300_dark_100
    return color
  }};
`;
const WinBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 2px;
`;
const Question = styled(Box)`

  color: #fff;
  background-color: ${props => props.theme.color.gray_500}
}

;
`


const TableTr = ({
                     disableTeam,
                     rank,
                     live,
                     name,
                     status,
                     url_name,
                     riot_id,
                     riot_tag,
                     puuId,
                     championship_name,
                     team,
                     tier,
                     division,
                     lp,
                     lose,
                     win,
                     position,
                     most,
                     win_table,
                     region,
                     positionRank,
                     topPosition,
                     score,
                 }) => {

    const {t} = useTranslation();
    const theme = useTheme();

    const getWinLoseText = (value) => {
        if (value === 9) {
            return '?'
        }
        if (value === 1) {
            return 'W'
        }
        if (value === 0) {
            return 'L'
        }
    }


    const onClickName = () => {
        storePuuIdLocal(`${riot_id}-${riot_tag}`, region, puuId)
    }

    const getLaneName = useCallback((lane) => {
        switch (lane.toLowerCase()) {
            case 'top':
                return t('gameAnalytics.top')
            case 'jungle':
                return t('gameAnalytics.jungle')
            case 'middle':
                return t('gameAnalytics.mid')
            case 'bot':
                return t('gameAnalytics.bot')
            case 'supporter':
                return t('gameAnalytics.support')
            default:
                break;
        }
    }, []);
    const aiScoreText = (value) => {
        if (value >= 75) {
            return AI_SCORE_PERFECT_TEXT
        } else if (value >= 50) {
            return AI_SCORE_PERFECT_TEXT;
        } else if (value >= 25) {
            return AI_SCORE_GOOD_TEXT;
        }
        return AI_SCORE_VERY_BAD_TEXT
    }

    return (
        <TR className={theme.color.theme}>
            <Td>
                <FlexBox justifyContent={'center'}>
                    <RankSpan>{positionRank ? getLaneName(position) : rank}</RankSpan>
                </FlexBox>
            </Td>
            <Td>
                <LiveBlock live={live}/>
            </Td>
            <Td>
                <FlexBox>
                    <RelativeBox>
                        <EventPlayer name={status === 'pro' ? championship_name : name} width={36} height={36}
                                     puuId={puuId} riot_id={riot_id}
                                     riot_tag={riot_tag} status={status} url_name={status === 'pro' ? url_name : name}
                                     region={region}
                        />
                        <AbsoluteBox>
                            <SmallInfluInfo status={status}/>
                        </AbsoluteBox>
                    </RelativeBox>
                    <SummonerBox>
                        <SummonerName onClick={onClickName}>
                            {name}
                        </SummonerName>
                        {(team && team !== '-') && <TeamBox>
                            <LeaderImage src={`${process.env.REACT_APP_ROOT_IMG}assets/team_logos/${team}.png`}
                                         height={12}
                                         alt={''}
                                         onError={(e) => e.target.style.display = 'none'}
                            />
                            <TeamName>
                                {team}
                            </TeamName>
                        </TeamBox>}
                        <TeamBox>
                            <TeamName color={aiScoreText(score)}>
                                {score}
                            </TeamName>
                        </TeamBox>
                    </SummonerBox>
                </FlexBox>
            </Td>
            <Td>
                <FlexBox justifyContent={'center'}>
                    <TagWrapper>
                        <TierTag
                            fontSize={'11px'}
                            padding={'4px 4px'}
                            tier={tier}
                            division={romanNumberConverter(division)}
                        />
                    </TagWrapper>

                    <LPText>
                        {threedigits(lp)}
                        <LP>
                            LP
                        </LP>
                    </LPText>
                </FlexBox>
            </Td>
            <Td>
                <WinRateWrapper>
                    <RankingWinRate
                        lose={lose}
                        win={win}
                    />
                </WinRateWrapper>
            </Td>
            <Td>
                <LaneBox>
                    <ImageLane
                        lane={topPosition}
                        color={theme.color.theme === 'dark' ? 'light' : 'dark'}
                    />
                </LaneBox>
            </Td>
            <Td>
                <ChampionBox>
                    {most?.map((item,index) => {
                        return (
                            <div className={'champion-div'} key={index}>
                                <ChampionImageWithLane champion_id={item}/>
                            </div>
                        )
                    })}
                </ChampionBox>
            </Td>
            <Td>
                <WinBox>
                    {Array.from({length: 10}).map((_, index) => {
                        const value = win_table?.[index];
                        const calcValue = (value !== 0 && value !== 1) ? 9 : value;
                        return (
                            <WinLoseBox win={calcValue} key={index}>
                                {getWinLoseText(calcValue)}
                            </WinLoseBox>
                        )
                    })}
                    <Question>
                        ?
                    </Question>
                </WinBox>
            </Td>
        </TR>
    )
}

const Live = styled.div`
  background-color: #EB5B5B;
  width: 53px;
  height: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  color: #fff;
  font-weight: 700;
  border-radius: 4px;
  animation: blink infinite 2000ms cubic-bezier(0, .15, .46, 1.03);
  @keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
  }
`;

const LiveBlock = ({live}) => {
    if (live !== 1) {
        return null
    }
    return (
        <Live>
            IN GAME
        </Live>
    )
}

export default EventSoopTable