import React, {Suspense} from 'react';
import {useTranslation} from "react-i18next";
import useTitleAndDescription from "../../../common/hooks/useTitleAndDescription";
import {Header, Loading} from "../../common";
import CenterContentBox from "../../Champions/ContentWrapper";
import {ErrorBoundary} from "react-error-boundary";
import styled from "styled-components";
import ExtraContent from "./ExtraContent";

function Fallback({error, resetErrorBoundary}) {
    // Call resetErrorBoundary() to reset the error boundary and retry the render.

    return (
        <div role="alert">
            <p>Something went wrong:</p>
            <pre>{error.message}</pre>
        </div>
    );
}

const Wrapper = styled.div`
`;
const ExtraEvent = () => {
    const {t} = useTranslation();
    useTitleAndDescription({
        title: t('gemagema.headerTitle'),
        description: t('gemagema.headerDesc')
    })
    return (
        <Wrapper>
            <Header/>
            <CenterContentBox disableSponsor={true}>
                <ErrorBoundary FallbackComponent={Fallback}>
                    <Suspense fallback={<Loading/>}>
                        <ExtraContent/>
                    </Suspense>
                </ErrorBoundary>
            </CenterContentBox>
        </Wrapper>
    );
};

export default ExtraEvent;