import React, {useCallback} from 'react';
import styled, {useTheme} from "styled-components";
import {Link, useHistory, useLocation} from "react-router-dom";
import useChampionFunc from "../../../../../../common/hooks/useChampionFunc";
import {getRegionServer, multiplyNumber, scoreColor, threedigits} from "../../../../../../function";
import TierTag from "../../../../../common/TierTag";
import {useSelector} from "react-redux";
import {ColumnBox, FlexBox} from "../../../../../ui/styled";
import InfluInfo from "../../../../../summoner/Profile/InfluInfo";
import ChampionImageWithLane from "../../../../../common/ChampionImageWithLane";

const Wrapper = styled.tr`
  position: relative;
  border-bottom: 1px solid ${props => props.theme.color.gray_200};
  height: 52px;

  &:hover {
    background-color: ${props => props.theme.color.white_200};
  }

  td:not(:last-of-type) {
    cursor: pointer;
  }
`;

const Content = styled.td`
  vertical-align: middle;
  background-color: ${props => props.test && "red"};

  .imgChamp {
    width: 36px;
    height: 36px;
    margin: 0;
  }

  .imgDiv {
    border-radius: 50%;
  }

`;

const TempWrapper = styled.div`
  font-size: 12px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;


const RegionText = styled.span`
  font-size: 12px;
  margin: ${props => props.margin || 0};
  color: ${props => props.theme.color.dark_100};
`;

const SummonerText = styled.div`
  width: 16ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-bottom: 0.18em;

  &:hover {
    text-decoration: underline;
  }
`;


const LPText = styled(RegionText)`
  color: ${props => props.theme.color.dark_200};
`;

styled(RegionText)`
  width: 5ch;
`;


const InflueWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  height: 100%;
`;

const CustomLink = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 51px;
`;
const MasteryRankTableContent = ({
                                     tag,
                                     filterRegion,
                                     champion_id,
                                     tier,
                                     division,
                                     lp,
                                     riot_id_name,
                                     play_rate,
                                     games,
                                     win_rate,
                                     rank,
                                     region,
                                     influData,

                                 }) => {

    useSelector((state) => state?.gameVersion.version);
    const {getChampion} = useChampionFunc();
    getChampion(champion_id);
    useHistory();
    const location = useLocation();
    const theme = useTheme();


    const navigateToSummoner = useCallback(() => {
        const serverRegion = getRegionServer(region).toLowerCase();
        return (`/summoner/${serverRegion}/${riot_id_name}-${tag}/mastery?champion=${champion_id}`)
    }, [location.search, riot_id_name, region, tag]);


    return (
        <Wrapper>

            <Content>
                <CustomLink to={navigateToSummoner()}>
                    <TempWrapper>
                        {rank}
                    </TempWrapper>
                </CustomLink>
            </Content>
            <Content>
                <CustomLink to={navigateToSummoner()}>
                    <TempWrapper>
                        <RegionText>{getRegionServer(region)}</RegionText>
                    </TempWrapper>
                </CustomLink>
            </Content>
            <Content>

                <CustomLink to={navigateToSummoner()}>
                    <FlexBox padding={'0 0 0 30px'}>
                        <ChampionImageWithLane
                            champion_id={champion_id}
                            navigateToMastery={filterRegion}
                        />
                        <ColumnBox margin={'0 0 0 8px'} height={'36px'}>
                            <div>
                                <SummonerText>{riot_id_name}</SummonerText>
                            </div>
                            {influData.status ? <InflueWrapper>
                                <InfluInfo
                                    textColor={theme.color.background_49}
                                    influData={influData}
                                />
                            </InflueWrapper> : null}
                        </ColumnBox>
                    </FlexBox>
                </CustomLink>
            </Content>
            <Content>

                <CustomLink to={navigateToSummoner()}>
                    <TempWrapper>
                        <TierTag
                            fontSize={'12px'}
                            padding={'4px 4px'}
                            tier={tier}
                            division={division}
                        />
                        <RegionText margin={'0 0 0 10px'}>{threedigits(lp)}<LPText> LP</LPText></RegionText>
                    </TempWrapper>
                </CustomLink>
            </Content>
            <Content>

                <CustomLink to={navigateToSummoner()}>
                    <TempWrapper>
                        <RegionText>{multiplyNumber(play_rate, 100)}<LPText> %</LPText></RegionText>
                    </TempWrapper>
                </CustomLink>
            </Content>
            <Content>

                <CustomLink to={navigateToSummoner()}>
                    <TempWrapper>
                        <RegionText>{threedigits(games)}</RegionText>
                    </TempWrapper>
                </CustomLink>
            </Content>
            <Content>

                <CustomLink to={navigateToSummoner()}>
                    <TempWrapper>
                        <RegionText>{scoreColor(multiplyNumber(win_rate, 100,
                        ))}<LPText> %</LPText></RegionText>
                    </TempWrapper>
                </CustomLink>
            </Content>
        </Wrapper>
    );
};


export default MasteryRankTableContent;
