import React, {memo, useEffect, useRef} from 'react';
import styled from "styled-components";
import VenatusAd from "./VenatusAd";

const SmallRail = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 250px;
  margin-bottom: 15px;
`;


const LeftSideWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
`;


const StickyBox = styled.div`
  position: relative;
  overflow: hidden;
`;


const StickyBoxWrapper = styled.div`
  position: fixed;
  pointer-events: none;
  overflow: hidden;
  text-align: center;
`;

const LeftRailAd = memo(() => {
    const ref = useRef();


    useEffect(() => {
        if (ref?.current?.style) {
            ref.current.style.inset = `100px ${(window.innerWidth + 1110 + 12) / 2}px 0px 0px`;
            window.addEventListener('resize', () => {
                if (ref?.current?.style?.inset) {
                    ref.current.style.inset = `100px ${(window.innerWidth + 1110 + 12) / 2}px 0px 0px`;
                }
            })
        }
    }, [])

    return (
        <StickyBoxWrapper ref={ref}>
            {/*<SmallRail data-ad={'left-rail-1'}>*/}
            {/*    <VenatusAd placementName={'vertical_sticky'}/>*/}
            {/*</SmallRail>*/}
            <LeftSideWrapper data-ad={"left-rail-2"}>
                <VenatusAd placementName={'vertical_sticky-left'}/>
            </LeftSideWrapper>
        </StickyBoxWrapper>
    )
})

export default LeftRailAd;