import React from 'react';
import styled from "styled-components";
import NewTabLink from "../../Link/NewTabLink";

const ImgBox = styled.button`
  &:hover {
    scale: 1.2;
    transition: scale .2s ease-in-out;
  }
`;
const SnsImage = ({influData, width = 24, height = 24, iconScale}) => {

    const snsSrc = [
        {
            title: 'kick',
            src: '/images/icn_sns_kick.svg',
            url: influData?.kick,
        },
        {
            title: 'chzzk',
            src: '/images/icn_sns_chzzk.svg',
            url: influData?.chzzk,
        },
        {
            title: 'twitter',
            src: '/images/icn_sns_x.svg',
            url: influData?.twitter,
        },
        {
            title: 'youtube',
            src: '/images/icn_sns_yout.svg',
            url: influData?.youtube,
        },
        {
            title: 'africa',
            src: '/images/icn_sns_afri.svg',
            url: influData?.afreecatv,
        },
        {
            title: 'twitch',
            src: '/images/icn_sns_twi.svg',
            url: influData?.twitch,
        },
        {
            title: 'leaguepidia',
            src: '/images/icn_sns_leag.svg',
            url: influData?.leaguepedia,
        },
        {
            title: 'facebook',
            src: '/images/icn_sns_facebook.svg',
            url: influData?.facebook,
        },
        {
            title: 'instagram',
            src: '/images/icn_sns_instagram.png',
            url: influData?.instagram,
        },
    ];

    return snsSrc.map((data, index) => {
        if (data.url !== '-' && data.url) {
            return (
                <NewTabLink to={{pathname: data.url}} className={'imgBox'} key={'indlue' + index}>
                    <ImgBox className={'imgBox'} iconScale={iconScale}>
                        <img
                            height={height}
                            width={width}
                            src={data.src}
                            alt={data.title}
                        />
                    </ImgBox>
                </NewTabLink>
            )
        } else {
            return <React.Fragment key={index + 'unknown'}></React.Fragment>
        }
    })
}

export default SnsImage;