import styled from "styled-components";
import React, {useEffect, useState} from "react";

const FadeInBox = styled.div`
  animation: fadein 1s linear;

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;
const SummonerCustomAd = ({indexArray, name}) => {
    const [adState, setAdState] = useState(() => {
        const getStoreValue = localStorage.getItem(`summonerBanner/${name}`);
        const setBannerValue = Number(getStoreValue) > indexArray.length ? '0' : getStoreValue;
        localStorage.setItem(`summonerBanner/${name}`, String(setBannerValue))
        return setBannerValue
    });


    useEffect(() => {
        const interval = setInterval(() => {
            setAdState(prev => {
                if (indexArray.length === 0) {
                    return '0'
                }
                const MAX_NUMBER = indexArray.length
                const next = Number(prev) + 1;
                if (next >= MAX_NUMBER) {
                    localStorage.setItem(`summonerBanner/${name}}`, '0')
                    return '0';
                }
                localStorage.setItem(`summonerBanner/${name}`, String(next))
                return String(next)
            })
        }, 5000);
        return () => {
            clearInterval(interval)
        }
    }, []);

    //
    // const [adState, setAdState] = useState(() => {
    //     const random = _.random(0, 100);
    //     // setAd(random)
    //     return random
    // });


    if (['F'].includes(indexArray[Number(adState)])) {
        return (
            <FadeInBox key={'faker'}>
                <a href={'https://www.instagram.com/faker/'} target={'_blank'}>
                    <img src={'./images/ad/ad_state1.png'} width={'100%'} height={120} alt={''}
                    />
                </a>
            </FadeInBox>
        )
    }

    if (['G'].includes(indexArray[Number(adState)])) {
        return (
            <FadeInBox key={'gumayusi'}>
                <a href={'https://www.instagram.com/t1_gumayusi/'} target={'_blank'}>
                    <img src={'./images/ad/ad_state2.png'} width={'100%'} height={120} alt={''}
                    />
                </a>
            </FadeInBox>
        )
    }

    if (['D1'].includes(indexArray[Number(adState)])) {
        return (
            <FadeInBox key={adState}>
                <a href={'https://deeplol-notice.notion.site/1caf4d23843f80f6825bc091b413b7c6?pvs=4'} target={'_blank'}>
                    <img src={'./images/ad/ad_deeplol_spot.png'} width={'100%'} height={120} alt={''}
                    />
                </a>
            </FadeInBox>
        )
    }

    if (['D2'].includes(indexArray[Number(adState)])) {
        return (
            <FadeInBox key={adState}>
                <a href={'https://deeplol-notice.notion.site/1caf4d23843f80f6825bc091b413b7c6?pvs=4'} target={'_blank'}>
                    <img src={'./images/ad/ad_deeplol_spot2.png'} width={'100%'} height={120} alt={''}
                    />
                </a>
            </FadeInBox>
        )
    }

    // return (
    //
    //     <a href={'https://chzzk.naver.com/3dadd937e0e47035b3c8c377815ce88a/community/detail/16202072'}
    //        target={'_blank'}>
    //         <img src={'./images/tan_ck_banner.png'} width={'100%'} height={52} alt={''}
    //              className={'add_to_tan'}/>
    //     </a>
    // )

    // return (
    //     <a href={'https://ch.sooplive.co.kr/podone1104/post/138536371'} target={'_blank'}>
    //         <img src={'./images/podo_kr_title_1.png'} width={'100%'} height={52} alt={''}
    //              className={'add_to_podo'}/>
    //     </a>
    // )
    return (
        <FadeInBox key={'ad_state1'}>
            <img src={'./images/ad/ad_state1.png'} width={'100%'} height={120} alt={''}
            />
        </FadeInBox>
    )
}

export default SummonerCustomAd