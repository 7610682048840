import React, {useCallback, useMemo} from 'react';
import {isAram, isSwarm, sortChampData} from "../../../../function";
import DamageGraph from "../../DetailTab/GameAnalytics/GameSummary/Table/TableContent/DamageGraph";
import styled, {useTheme} from "styled-components";


const DamageBackground = styled.div`
  width: 193px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  border-radius: 3px;
`;

const DamageGraphWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 193px;
`;

styled(DamageBackground)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 4.5px;

  .round-block:not(:last-of-type) {
    margin-right: 1px;
  }
`;


const RenderChart = ({queue, match, myMatch, aiSorted}) => {
    const theme = useTheme();

    const allDmg = useMemo(() => {
        if (Array.isArray(match?.participants_list) && match.participants_list.length > 0) {
            return match.participants_list.reduce((sum, data, index) => {
                if (sum.totaldmg < data.total_damage_dealt) {
                    sum.totaldmg = data.total_damage_dealt;
                }

                if (sum.totalTaken < data.total_damage_taken) {
                    sum.totalTaken = data.total_damage_taken;
                }

                return sum;
            }, {totaldmg: 0, totalTaken: 0});
        }

    }, [match]);


    const swarmAllDmg = useMemo(() => {
        if (Array.isArray(match?.participants_list) && match.participants_list.length > 0) {
            return match.participants_list.reduce((sum, data, index) => {
                if (sum.totaldmg < data.swarm_total_damage_dealt) {
                    sum.totaldmg = data.swarm_total_damage_dealt;
                }

                if (sum.totalTaken < data.total_damage_taken) {
                    sum.totalTaken = data.total_damage_taken;
                }

                return sum;
            }, {totaldmg: 0, totalTaken: 0});
        }


    },[match])

    const damageSorted = useMemo(() => {
        return sortChampData(match?.participants_list, [{value: "total_damage_dealt", sortType: false}]);
    }, [match, myMatch])

    const swarmDamageSorted = useMemo(() => {
        return sortChampData(match?.participants_list, [{value: "swarm_total_damage_dealt", sortType: false}]);
    }, [match, myMatch])

    const getDamageRank = useMemo(() => {
        if (Array.isArray(match?.participants_list) && isSwarm(match?.match_basic_dict?.queue_id)) {
            const index = swarmDamageSorted.findIndex((data) => data.puu_id === myMatch.puu_id);
            return index + 1;
        }
        if (Array.isArray(match?.participants_list) && isAram(match?.match_basic_dict?.queue_id,match?.match_basic_dict?.map_id)) {
            const index = damageSorted.findIndex((data) => data.puu_id === myMatch.puu_id);
            return index + 1;
        }
        return 0;
    }, [match, myMatch, aiSorted]);



    const barColor = useCallback(() => {
        switch (getDamageRank) {
            case 1:
                return '#F3A634';
            case 2:
                return '#F3A634';
            case 3:
                return '#259A7E';
            case 4:
                return '#259A7E';
            case 5:
                return '#259A7E';
            case 6:
                return '#DE5353';
            case 7:
                return '#DE5353';
            case 8:
                return '#DE5353';
            default:
                return '#69788D';
        }
    }, [getDamageRank]);

    const swarmBarColor = () => {
        switch (getDamageRank) {
            case 1:
                return '#F3A634'
            default:
                return '#259A7E'
        }
    }


    if (isSwarm(queue)) {
        return (<DamageGraphWrapper>
            <DamageBackground>
                <DamageGraph
                    grade={getDamageRank}
                    indicator={true}
                    reverse={true}
                    dmg={myMatch.swarm_total_damage_dealt}
                    total={swarmAllDmg?.totaldmg}
                    color={'#313131'}
                    barColor={swarmBarColor()}
                    bgColor={theme.color.gray_100}
                />
            </DamageBackground>
        </DamageGraphWrapper>)
    }


    if (isAram(queue,match?.match_basic_dict?.map_id)) {
        return (<DamageGraphWrapper>
            {/*<DamageGraphHeader>{t('gameAnalytics.damage')}</DamageGraphHeader>*/}
            <DamageBackground>
                <DamageGraph
                    grade={getDamageRank}
                    indicator={true}
                    reverse={true}
                    dmg={myMatch.total_damage_dealt}
                    total={allDmg?.totaldmg}
                    color={'#313131'}
                    barColor={barColor()}
                    bgColor={theme.color.gray_100}
                />
            </DamageBackground>
        </DamageGraphWrapper>)
    }


    return null;
}

export default RenderChart