import {useSuspenseQuery} from "@tanstack/react-query";
import {getDivisionValue, getTierValue, romanNumberConverter, sortChampData} from "../../function";
import {secretApi} from "../../api/api";

const setEventName = (name) => {
    switch (name) {
        case 'たぬき忍者':
            return "TanukiNinja"
        case 'しゃるる':
            return "Syaruru"
        case 'raizin':
            return "Raizin"
        case 'jasper':
            return "Jasper"
        case 'げまげま':
            return "ROOM"
        default:
            return ""
    }
}

const teamNameValue = (name) => {
    switch (name) {
        case 'HLE' :
            return 1
        case 'GEN':
            return 2
        case 'DK':
            return 3
        case 'NS':
            return 4
        case 'KT':
            return 5
        case 'T1':
            return 6
        case 'DRX':
            return 7
        case 'DNF':
            return 8
        case 'BRO':
            return 9
        case 'BFX':
            return 10
        default:
            return 11
    }
}

const useEventAPI = () => {
    const query = useSuspenseQuery({
        queryKey: ['event'],
        queryFn: async () => {
            const url = new URL(`${process.env.REACT_APP_CACHE_API}summoner/total_pro_event`);
            const event = await secretApi.get(url.toString());
            // getTierCutAPI('KR'), getTierCutAPI('JP')

            const eventResult = event.data.teams_info

            const eventResultAddTierScore = Object.values(eventResult).map((data) => {
                return data.map((item) => {
                    const splitTier = item?.tier?.split(" ");
                    const tier = splitTier[0];
                    const division = romanNumberConverter(splitTier?.[1]);
                    return {
                        ...item,
                        tierScore: getTierValue(tier) + getDivisionValue(division)
                    }
                })
            })

            const data = Object.values(eventResult);


            const filterEmptyArray = data.filter((item) => {
                return Object.keys(item[0]).length > 1
            })


            const teamList = Object.keys(eventResult)


            const normalizeData = eventResultAddTierScore.reduce((sum, cur) => {
                return [...sum, ...cur]
            }, []);


            const top = normalizeData.filter((data) => data.position.toLowerCase() === 'top');
            const jungle = normalizeData.filter((data) => data.position.toLowerCase() === 'jungle');
            const middle = normalizeData.filter((data) => data.position.toLowerCase() === 'mid');
            const bot = normalizeData.filter((data) => data.position.toLowerCase() === 'bot');
            const supporter = normalizeData.filter((data) => data.position.toLowerCase() === 'support');


            const sort = [{value: "tierScore", sortType: false}, {value: "lp", sortType: false}, {
                value: "games",
                sortType: false
            }, {
                value: "wins",
                sortType: false
            }]

            const topSort = sortChampData(top, sort)
            const jungleSort = sortChampData(jungle, sort)
            const middleSort = sortChampData(middle, sort)
            const botSort = sortChampData(bot, sort)
            const supporterSort = sortChampData(supporter, sort)


            const teamNameAddValue = teamList.map((data) => {
                return {
                    title: data,
                    value: teamNameValue(data)
                }
            })


            const teamNameResult = sortChampData(teamNameAddValue, [{
                value: "value",
                sortType: true
            }]).map((data) => data.title)
            const teamListWithFullName = Object.values(eventResult).map((data, index) => {
                const teamName = data[0].pro_team
                return {
                    title: teamList[index],
                    logo: teamName
                }
            })

            return {
                teamListByPosition: {
                    top: topSort,
                    jungle: jungleSort,
                    middle: middleSort,
                    bot: botSort,
                    supporter: supporterSort
                },
                teamList: teamNameResult,
                teamListWithFullName: teamListWithFullName,
                eventFilter: [],
                list: Object.values(eventResult),
            }
        },
        retry: 0,
        refetchOnWindowFocus: false,
    })
    return {
        eventQuery: query,
    }
};

export default useEventAPI;