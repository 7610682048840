import React, {useCallback} from 'react';
import styled, {useTheme} from "styled-components";
import {Link, useHistory, useLocation} from "react-router-dom";
import useChampionFunc from "../../../../../../common/hooks/useChampionFunc";
import CRTableRanking
    from "../../../../../Champions/ChampionsContent/ChampionsRank/ChampionsRankingTable/ChampionsRankingTableContent/CRTableRanking";
import CRTChampion
    from "../../../../../Champions/ChampionsContent/ChampionsRank/ChampionsRankingTable/ChampionsRankingTableContent/CRTChampion";
import CRTTier
    from "../../../../../Champions/ChampionsContent/ChampionsRank/ChampionsRankingTable/ChampionsRankingTableContent/CRTTier";
import DamageGraph from "../../../../../summoner/DetailTab/GameAnalytics/GameSummary/Table/TableContent/DamageGraph";
import {threedigits} from "../../../../../../function";

const Wrapper = styled.tr`
  overflow: hidden;
  border-bottom: 1px solid ${props => props.theme.color.gray_200};

  &:hover {
    background-color: ${props => props.theme.color.white_200};
  }

  td:not(:last-of-type) {
    cursor: pointer;
  }
`;

const Content = styled.td`
  vertical-align: middle;
`;

const TempWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const WinPickBanText = styled.span`
  text-align: center;
  font-size: 12px;
  color: ${props => props.theme.color.dark_100};
`;

const PercentText = styled.span`
  font-size: 12px;
  color: ${props => props.theme.color.dark_200};
  opacity: 0.6;
`;

const DamageWrapper = styled.div`
  width: 100px;
`;

const ChampWrapper = styled.div`
  width: 171px;
`;

const CustomLink = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
    height: 51px;
`;

const AramRankTableContent = ({
                                  version,
                                  rank,
                                  winRate,
                                  pickRate,
                                  tier,
                                  champion,
                                  lane,
                                  rankDelta,
                                  highestDPM,
                                  dpm,
                                  pentaKills
                              }) => {

    const {getUrlNameByChampion, getChampionName} = useChampionFunc();
    const renderName = getChampionName(champion);
    const history = useHistory();
    const location = useLocation();
    const theme = useTheme();

    const navigateToBuild = useCallback(() => {
        const champName = getUrlNameByChampion(champion);
        return (`/champions/${champName}/build/aram`)
    }, [champion, lane, location.search]);


    return (
        <Wrapper>
            <Content>
                <CustomLink to={navigateToBuild()}>
                    <CRTableRanking
                        rank={rank}
                        value={rankDelta}
                    />
                </CustomLink>
            </Content>
            <Content>

                <CustomLink to={navigateToBuild()}>
                    <ChampWrapper>
                        <CRTChampion
                            disableOnClick={true}
                            lane={lane}
                            imageName={champion?.image_name}
                            paddingLeft={'16px'}
                            championName={renderName}
                            enChampName={champion?.champion_name_en}
                            version={version}
                        />
                    </ChampWrapper>
                </CustomLink>
            </Content>
            <Content>

                <CustomLink to={navigateToBuild()}>
                    <CRTTier
                        tier={tier}
                    />
                </CustomLink>
            </Content>
            <Content>
                <CustomLink to={navigateToBuild()}>
                    <TempWrapper>
                        <WinPickBanText>{(winRate * 100).toFixed(1)}<PercentText> %</PercentText></WinPickBanText>
                    </TempWrapper>
                </CustomLink>
            </Content>
            <Content>

                <CustomLink to={navigateToBuild()}>
                    <TempWrapper>
                        <WinPickBanText>{(pickRate * 100).toFixed(1)}<PercentText> %</PercentText></WinPickBanText>
                    </TempWrapper>
                </CustomLink>
            </Content>
            <Content>

                <CustomLink to={navigateToBuild()}>
                    <TempWrapper>
                        <DamageWrapper>
                            <DamageGraph
                                bgColor={theme.color.gray_100}
                                color={theme.color.dark_200}
                                barColor={'#EF4D8A'}
                                dmg={dpm}
                                total={highestDPM}
                            />
                        </DamageWrapper>
                    </TempWrapper>
                </CustomLink>
            </Content>
            <Content>
                <CustomLink to={navigateToBuild()}>
                    <TempWrapper>
                        <WinPickBanText>{threedigits(pentaKills)}</WinPickBanText>
                    </TempWrapper>
                </CustomLink>
            </Content>
        </Wrapper>
    );
};


const isEquals = (prev, next) => {
    return prev.champion?.champion_id === next.champion?.champion_id;
}
export default React.memo(AramRankTableContent, isEquals);
