import React, {useCallback, useMemo, useState} from 'react';
import styled, {useTheme} from "styled-components";
import useEventAPI from "../../common/hooks/useEventAPI";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import EventSoopTable from "./EventSoopTable";
import {ImageLane} from "../common";
import {sortChampData} from "../../function";

const Wrapper = styled.div`
  width: 100%;
`;
const BannerWrapper = styled.div`
  margin-bottom: 14px;
`;
const TitleText = styled.span`
  font-size: 28px;
  color: ${props => props.theme.color.dark_100};
  font-weight: 700;
`;

const CardWrapper = styled.div`
  background-color: ${props => props.theme.color.site_main_color};
  border: 2px solid ${props => props.theme.color.green_100};
  border-radius: 6px;
  overflow: hidden;

  margin-top: 14px;
`;
const setInitialRegion = (region, eventRegionList) => {
    const firstEvent = eventRegionList[0];
    const has = eventRegionList.includes(region);
    if (has) {
        return region
    }
    const hasKor = eventRegionList.includes('KR');
    if (hasKor) {
        return 'KR'
    }
    return firstEvent
}

const ListWrapper = styled.div`
  & + & {
    margin-top: 20px;
  }
`;
const EventContent = () => {
    const {t} = useTranslation()
    const theme = useTheme();
    const {eventQuery} = useEventAPI();
    const region = useSelector((state) => state.region.result);
    const currentLang = useSelector((state) => state.translation.lang);
    // const [selectedRegion, setSelectedRegion] = useState(setInitialRegion(region, eventQuery.data.eventRegionList));
    // const [selectedType, setSelectedType] = useState(eventQuery.data.eventFilter[0]);
    const [subjectFilter, setSubjectFilter] = useState('all');
    const [filterTeam, setFilterTeam] = useState('');


    const onClickTeam = (team) => {
        try {
            setSubjectFilter('');


            if (filterTeam === team) {
                setFilterTeam('')
                setSubjectFilter('all')
            } else {
                setFilterTeam(team)
            }
        } catch (e) {

        }
    }

    const onClickSubject = (data) => {
        try {
            if (data === 'byRole') {
                setSubjectFilter(data);
                setFilterTeam('');
            }
            if (data === 'all' && subjectFilter === 'all') {
                setSubjectFilter('')
                setFilterTeam('')
                return
            }
            if (data === 'all') {
                setSubjectFilter('all')
                setFilterTeam('');
                return;
            }

            if (data === subjectFilter) {
                setSubjectFilter('all')
                setFilterTeam('');
                return;
            }
            // const allList = Object.values(eventQuery.data.list);
            // const filtered = allList.filter((item) => item[0].tot === data);
            // if (filtered.length > 0) {
            //     const teamList = filtered.map((item) => item[0])
            //     setFilterTeam('')
            // } else {
            //     setFilterTeam('')
            // }
            setSubjectFilter(data)
        } catch (e) {
            console.log(e)
        }
    }


    const list = useMemo(() => {
        try {
            const eventList = Object.values(eventQuery.data.list)
            const filterTeamList = eventList.filter((data, index) => filterTeam === (eventQuery.data.teamListWithFullName?.[index]?.title) || subjectFilter === 'all');
            const normalizeData = filterTeamList.reduce((sum, cur) => {
                return [...sum, ...cur]
            }, []);


            const sort = [{value: "tierScore", sortType: false}, {value: "lp", sortType: false}, {
                value: "games",
                sortType: false
            }, {
                value: "wins",
                sortType: false
            }]

            return sortChampData(normalizeData, sort);
        } catch (e) {
            return [];
        }
    }, [eventQuery.data.list, filterTeam, subjectFilter])



    //
    // const currentTierOff = useMemo(() => {
    //     try {
    //         const normalizedData = eventQuery.data.normalizeData
    //         const findData = eventQuery.data.tier_cut_map.find((data) => data.platform_id === selectedRegion);
    //         const challengerFilter = normalizedData.filter((data) => data.tier === 'CHALLENGER');
    //         const grandmasterFilter = normalizedData.filter((data) => data.tier === 'GRANDMASTER');
    //         const masterFilter = normalizedData.filter((data) => data.tier === 'MASTER');
    //
    //         const masterList = masterFilter.map((data) => data.lp);
    //         const grandMasterList = grandmasterFilter.map((data) => data.lp);
    //         const challengerList = challengerFilter.map((data) => data.lp);
    //
    //
    //         const masterMax = Math.max(...masterList)
    //         const grandMasterMax = Math.max(...grandMasterList)
    //         const challengerMax = Math.max(...challengerList)
    //
    //
    //         if (findData) {
    //             return {
    //                 challenger_1st: challengerMax > findData.challenger_1st ? challengerMax : findData.challenger_1st,
    //                 challenger_cut_off: grandMasterMax > findData.challenger_cut_off ? grandMasterMax : findData.challenger_cut_off,
    //                 grandmaster_cut_off: masterMax > findData.grandmaster_cut_off ? masterMax : findData.grandmaster_cut_off,
    //             }
    //         }
    //         return {
    //             challenger_1st: challengerMax,
    //             challenger_cut_off: grandMasterMax,
    //             grandmaster_cut_off: masterMax,
    //         }
    //     } catch (e) {
    //         console.log(e)
    //         return eventQuery?.data.tier_cut_map?.[0]
    //     }
    // }, [eventQuery.data.list, selectedRegion, list]);

    if (list.length === 0 && subjectFilter !== 'byRole') {
        return (
            <Wrapper>
                <GreenBox/>
                <EventFilter subjectList={eventQuery.data.subjectList} teamList={eventQuery.data.teamListWithFullName}
                             filterTeam={filterTeam}
                             setFilterTeam={onClickTeam} setSubjectFilter={onClickSubject}
                             subjectFilter={subjectFilter}
                             list={list}
                />
            </Wrapper>
        )
    }
    return (
        <Wrapper>
            <GreenBox/>
            <EventFilter subjectList={eventQuery.data.subjectList} teamList={eventQuery.data.teamListWithFullName}
                         filterTeam={filterTeam}
                         setFilterTeam={onClickTeam} setSubjectFilter={onClickSubject} subjectFilter={subjectFilter}
                         list={list}
            />
            <MapList
                teamList={eventQuery.data.teamList}
                subjectFilter={subjectFilter}
                list={list}
                positionList={eventQuery.data.teamListByPosition}
            />
        </Wrapper>
    );
};

const PositionBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 12px;
  border-bottom: 2px solid ${props => props.theme.color.green_100};

  .imgLane {
    width: 22px;
    height: 22px;
  }
`;
const PositionSpan = styled.span`
  font-size: 24px;
  font-weight: 700;
  color: ${props => props.theme.color.green_100};
`;
const PositionTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 36px;
  margin-left: 10px;
`;
const PositionBox = ({position}) => {
    const theme = useTheme();
    const {t} = useTranslation();

    const getLaneName = useCallback((lane) => {
        switch (lane.toLowerCase()) {
            case 'top':
                return t('gameAnalytics.top')
            case 'jungle':
                return t('gameAnalytics.jungle')
            case 'middle':
                return t('gameAnalytics.mid')
            case 'bot':
                return t('gameAnalytics.bot')
            case 'supporter':
                return t('gameAnalytics.support')
            default:
                break;
        }
    }, []);


    return (
        <PositionBoxWrapper>
            <ImageLane
                lane={position}
                color={theme.color.theme === 'dark' ? 'light' : 'dark'}
            />
            <PositionTextWrapper>
                <PositionSpan>{getLaneName(position)} 포지션 랭킹</PositionSpan>
            </PositionTextWrapper>
        </PositionBoxWrapper>
    )


}

const MapList = ({subjectFilter, list, positionList, teamList}) => {
    if (subjectFilter === 'byRole') {
        return (
            <>
                <ListWrapper>
                    <CardWrapper>
                        <PositionBox position={'top'}/>
                        <EventSoopTable list={positionList.top} team={""}
                                        disableTeam={true} most={true}/>
                    </CardWrapper>
                </ListWrapper>
                <ListWrapper>
                    <CardWrapper>
                        <PositionBox position={'jungle'}/>
                        <EventSoopTable list={positionList.jungle} team={""}
                                        disableTeam={true} most={true}/>
                    </CardWrapper>
                </ListWrapper>
                <ListWrapper>
                    <CardWrapper>

                        <PositionBox position={'middle'}/>
                        <EventSoopTable list={positionList.middle} team={""}
                                        disableTeam={true} most={true}/>
                    </CardWrapper>
                    {/*<EventChart list={item}*/}
                    {/*            tierOff={currentTierOff}/>*/}
                </ListWrapper>
                <ListWrapper>
                    <CardWrapper>

                        <PositionBox position={'bot'}/>
                        {/*<EventTeamComment leader={leader} team={leader.team_name} teamList={item} tag={score_sum}/>*/}
                        <EventSoopTable list={positionList.bot} team={""}
                                        disableTeam={true} most={true}/>
                    </CardWrapper>
                    {/*<EventChart list={item}*/}
                    {/*            tierOff={currentTierOff}/>*/}
                </ListWrapper>
                <ListWrapper>
                    <CardWrapper>
                        <PositionBox position={'supporter'}/>

                        {/*<EventTeamComment leader={leader} team={leader.team_name} teamList={item} tag={score_sum}/>*/}
                        <EventSoopTable list={positionList.supporter} team={""}
                                        disableTeam={true} most={true}/>
                    </CardWrapper>
                    {/*<EventChart list={item}*/}
                    {/*            tierOff={currentTierOff}/>*/}
                </ListWrapper>
            </>
        )
    }
    return (
        <ListWrapper>
            <CardWrapper>
                {/*<EventTeamComment leader={leader} team={leader.team_name} teamList={item} tag={score_sum}/>*/}
                <EventSoopTable

                    list={list}
                    positionRank={false}
                    most={true}/>
            </CardWrapper>
            {/*<EventChart list={item}*/}
            {/*            tierOff={currentTierOff}/>*/}
        </ListWrapper>
    )

}

const EventFilterWrapper = styled.div`
  margin-bottom: 10px;
`;

const RegionBox = styled.div`
  gap: 4px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  & + & {
    margin-top: 10px;
  }
`;

const FilterButton = styled.button`
  font-size: 12px;
  font-weight: 500;
  color: ${props => props.theme.color.dark_200};
  display: flex;
  flex-direction: row;
  min-width: 90px;
  justify-content: center;
  align-items: center;
  height: 32px;
  border: 1px solid ${props => props.theme.color.default_border_color};
  margin-inline: -1px;
  background-color: ${props => props.theme.color.site_main_color};
  border-radius: 6px;


  &:hover {
    background-color: ${props => props.theme.color.white_200};
  }

  &.selected {
    border-color: ${props => props.selectedBorderColor || props.theme.color.green_100};
    background-color: ${props => props.selectedColor || props.theme.color.green_200};
    color: ${props => props.selectedFontColor || props.theme.color.green_100};
    font-weight: 700;
    z-index: 2;
  }
`;

const AllFilterButton = styled(FilterButton)`
  min-width: auto;
  padding: 0 10px;
`;
const ImageWrapper = styled.div`
  margin-right: 4px;
`;


const EventFilter = ({teamList, filterTeam, setFilterTeam, subjectFilter, setSubjectFilter, subjectList, list}) => {

    const {t} = useTranslation();
    const theme = useTheme();

    const getTypeBackgroundColor = (type) => {
        switch (type) {
            case 'All':
                return "";
            case 'PRO':
                return theme.color.purple_400;
            case 'STRM' :
                return theme.color.red_700;
            default:
                return ""
        }
    }
    const getTypeFontColor = (type) => {
        switch (type) {
            case 'All':
                return "";
            case 'PRO':
                return '#8775FB';
            case 'STRM' :
                return '#EE6787';
            default:
                return ""
        }
    }


    const getTypeBorderColor = (type) => {
        switch (type) {
            case 'All':
                return "";
            case 'PRO':
                return '#8775FB';
            case 'STRM' :
                return '#EE6787';
            default:
                return ""
        }
    }
    return (
        <EventFilterWrapper>
            <RegionBox>
                <AllFilterButton className={subjectFilter === 'all' ? 'selected' : ""}
                                 onClick={() => setSubjectFilter('all')}
                >{t('championAnalyze.all')}</AllFilterButton>
                {/*{subjectList.map((data) => {*/}
                {/*    return (*/}
                {/*        <FilterButton className={subjectFilter === data ? 'selected' : ""}*/}
                {/*                      key={data}*/}
                {/*                      onClick={() => setSubjectFilter(data)}*/}
                {/*        >{data}</FilterButton>*/}
                {/*    )*/}
                {/*})}*/}
                <AllFilterButton className={subjectFilter === 'byRole' ? 'selected' : ""}
                                 onClick={() => setSubjectFilter('byRole')}
                >{t('event.ByRole')}</AllFilterButton>

                {teamList.map((data, index) => {
                    return (
                        <TeamItem data={data.title} filterTeam={filterTeam} setFilterTeam={setFilterTeam} key={index}
                                  logo={data.logo}/>
                    )
                })}
            </RegionBox>
        </EventFilterWrapper>
    )
}


const TeamItem = ({filterTeam, data, setFilterTeam, logo}) => {
    const [onError, setOnError] = useState(false);
    return (
        <FilterButton className={filterTeam.includes(data) ? 'selected' : ""}
                      onClick={() => setFilterTeam(data)}>
            {!onError &&
                <ImageWrapper>
                    <img src={`${process.env.REACT_APP_ROOT_IMG}assets/team_logos/${logo}.png`}
                         height={20}
                         alt={''}
                         onError={() => {
                             setOnError(true)
                         }}
                    />
                </ImageWrapper>
            }
            {data}
        </FilterButton>
    )
}

const GreenBoxWrapper = styled.div`
  margin: 10px 0 20px;
  border: 2px solid ${props => props.theme.color.green_100};
  background-color: ${props => props.theme.color.green_200};
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 96px;
  color: ${props => props.theme.color.green_100};
  font-weight: bold;
  font-size: 24px;
  white-space: pre-wrap;
  text-align: center;
  line-height: normal;
`;

const GreenBox = () => {
    const {t} = useTranslation()
    return (
        <GreenBoxWrapper>
            {t('event.green')}
        </GreenBoxWrapper>
    )
}
export default EventContent;